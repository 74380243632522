.background-radial-gradient {
    background-color: hsl(218, 41%, 15%);
    background-image: radial-gradient(650px circle at 0% 0%,
        hsl(218, 41%, 35%) 15%,
        hsl(218, 41%, 30%) 35%,
        hsl(218, 41%, 20%) 75%,
        hsl(218, 41%, 19%) 80%,
        transparent 100%),
      radial-gradient(1250px circle at 100% 100%,
        hsl(218, 41%, 45%) 15%,
        hsl(218, 41%, 30%) 35%,
        hsl(218, 41%, 20%) 75%,
        hsl(218, 41%, 19%) 80%,
        transparent 100%);
  }
  
  #radius-shape-1 {
    height: 220px;
    width: 220px;
    top: -60px;
    left: -130px;
    background: radial-gradient(#44006b, #ad1fff);
    overflow: hidden;
  }
  
  #radius-shape-2 {
    border-radius: 38% 62% 63% 37% / 70% 33% 67% 30%;
    bottom: -60px;
    right: -110px;
    width: 300px;
    height: 300px;
    background: radial-gradient(#44006b, #ad1fff);
    overflow: hidden;
  }
  
  .bg-glass {
    background-color: hsla(0, 0%, 100%, 0.9) !important;
    backdrop-filter: saturate(200%) blur(25px);
    margin-top: 8em!important;
  }
  .loginWrap{
    .card{
        width: 450px;
        .form-control {
            border-radius: 0;
            border: none;
            outline: none;
            box-shadow: none;
            background: #fff;
            padding: 10px 20px !important;
            font-size: 22px;
            line-height: 28px;
            animation-name: onautofillcancel;
            min-height: auto;
            border: 0;
            background: transparent;
            transition: all .2s linear;
            border: 1px solid;
            border-radius: 5px;
        }
        .form-outline{
            position: relative;
            margin-top: 40px;

        }
        .form-label{
            position: absolute;
            top: -12px;
            left: 9px;
            background: #e8ecf2;
            padding: 0px 9px;
            font-size: 17px;
            color: #666262;
            }
            button{
                width: 100%;
                font-size: 19px;
                padding: 10px 0px;
                background: #000;
                color: #fff;
                border-radius: 5px;
            }
        }
    
  }
.blogListWrapper{
  margin-top: 8em;
  min-height: 100vh;

}

.blogModal{
  input{
    border: 1px solid;
    padding: 6px;
    border-radius: 3px;
  }
  select{
    border: 1px solid;
    padding: 7px 4px;
    width: 100%;
    margin-bottom: 10px;
  }
}
  @media only screen and (max-width: 600px){
	/*Big smartphones [426px -> 600px]*/
    .card{
        width: auto !important;
    }
}
.ql-editor{
  height: 200px !important;
}
.navWrapper{
  position: fixed;
  left: 0;
  background: #000;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  height: 100vh;
  width: 248px;
  top: 93px;
  padding-top: 10px;

    ul{
      li{
        list-style-type: none;
        margin: 18px 0px;
        padding-left: 20px;
        height: 35px;
        line-height: 35px;
        cursor: pointer;
        &.active{
          a{
            color: blue;

          }
          &:before {
            content: "";
            width: 10px;
            background: blue;
            display: flex;
            position: absolute;
            left: 0;
            height: 35px;
            border-radius: 11px;
        }
        }
        
      }
    }
}
.dashboard{
  margin: 139px 100px 139px 296px;
  margin-bottom: 0px;
  min-height: 100vh;
}