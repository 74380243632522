.info {
  &-section {
    padding: 111px 154px;
    background: url(../img/info.png);
    background-position: center left;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    height: 360px;

    @media (max-width: 560px) {
      height: 320px;
      padding: 60px 40px;
    }
  }

  &-text {
    font-style: italic;
    position: relative;
    font-size: 32px;
    line-height: 46px;
    color: $white;
    text-align: center;

    padding: 0px 150px;

    @media (max-width: 1440px) {
      font-size: 26px;
      line-height: 46px;
    }

    @media (max-width: 992px) {
      width: 100%;
    }

    @media (max-width: 820px) {
      font-size: 22px;
      line-height: 40px;
    }
  }
}

.shape {
  position: absolute;
  top: 0;
  height: 100%;
  right: 0;
}
.thumbnail {
  background: #ffffff;
  margin-bottom: 40px;
  img {
    width: 100%;
  }
  .caption {
    position: relative;
    h2 {
      font-style: normal;
      font-weight: 800;
      font-size: 24px;
      line-height: 30px;
      padding: 30px;
      color: #08110a;
      /* identical to box height */

      display: flex;
      align-items: center;
      text-transform: uppercase;

      /* 01 */

      color: #0a3e68;

      @media (max-width: 1440px) {
        line-height: 24px;
        font-size: 20px;
        padding: 22px;
      }

      @media (max-width: 1024px) {
        line-height: 20px;
        font-size: 16px;
        padding: 18px;
      }

      @media (max-width: 560px) {
        font-size: 16px;
        line-height: 20px;
        padding: 15px;
      }
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: center;

      /* Secondary */

      color: #77abd2;

      &.caption-power {
        padding: 12px 18px 9px;
        background: #2a7a13;
        position: absolute;
        top: -22px;
        line-height: 28px;
        right: 30px;
        color: #fff;
        font-size: 24px;
        font-weight: 800;

        @media (max-width: 1440px) {
          line-height: 20px;
          font-size: 18px;
        }

        @media (max-width: 1024px) {
          line-height: 15px;
          font-size: 13px;
        }

        @media (max-width: 560px) {
          font-size: 15px;
          line-height: 19px;
        }
      }
    }
  }
}

.img-responsive {
  max-width: 100%;
}
.info-section {
  height: 320px;
  padding: 60px 40px;
  background: #000 !important;
}
.hidden {
  display: none;
}
.contact {
  padding-inline: 200px;
  margin-bottom: 100px;
  .card {
    background-image: url(../img/contactCard.png);
    background-repeat: no-repeat;
    background-size: cover;
    color: #ffffff;
    padding: 32px;
    min-height: 350px;
    .title {
      font-family: "Outfit";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      letter-spacing: 1.00859px;
      text-transform: uppercase;
    }
    .address {
      padding-right: 10px;
      opacity: 0.7;
      font-family: "Inter";
    }
    p {
      font-size: 16px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #ffffff;
    }
  }
}
.mt-20 {
  margin-top: 20px;
}
