.portfolio-partners{
    .about-section{
        .about-wrap{
            img{
                display: none;
            }
            .about-content{
                .about-title{
                    width: 100%;
                }
            }
        }
    }
}