.contact {
  &-wrap {
    @include padding-left;
    margin-top: 137px;

    @media (max-width: 820px) {
      padding-right: 90px;
    }

    @media (max-width: 560px) {
      padding-right: 30px;
    }
  }

  &-info {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 40px;
    flex-wrap: wrap;
    margin-bottom: 20px;

    @media (max-width: 820px) {
      gap: 20px;
    }

    &-wrap {
      display: flex;
      flex-direction: column;
      row-gap: 80px;
      // row-gap: 150px;

      @media (max-width: 820px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 30px;
      }

      @media (max-width: 560px) {
        display: flex;
        row-gap: 60px;
      }
    }

    &-title {
      font-size: 16px;
      line-height: 20px;
      color: rgba($color: $dark, $alpha: 0.5);
      width: 10%;

      @media (max-width: 820px) {
        font-size: 14px;
        line-height: 18px;
        width: 15%;
      }
    }
  }

  &-detail {
    font-size: 16px;
    line-height: 26px;
    color: $dark;
    text-transform: uppercase;
    width: 70%;

    @media (max-width: 820px) {
      font-size: 13px;
      line-height: 19px;
    }

    @media (max-width: 560px) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &-link {
    font-size: 16px;
    line-height: 30px;
    color: $secondary;

    @media (max-width: 820px) {
      font-size: 12px;
      line-height: 19px;
    }

    @media (max-width: 560px) {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.detail-title-wrap {
  position: relative;
  width: 100%;
  padding-top: 30px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 2px;
    width: 334px;
    background: $secondary;

    @media (max-width: 820px) {
      width: 250px;
    }

    @media (max-width: 560px) {
      width: 300px;
    }
  }

  p {
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 30px;
    text-transform: uppercase;

    @media (max-width: 820px) {
      font-size: 17px;
      line-height: 20px;
    }
  }
}

.media-inq {
  &-wrap {
    width: 100%;
    height: 745px;
    padding: 125px 60px;
    background: url(../img/contact-right.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;

    @media (max-width: 820px) {
      height: auto;
      margin-top: 120px;
    }

    @media (max-width: 560px) {
      padding: 80px 40px;
    }

    .detail-title-wrap {
      &::before {
        background: $white;

        @media (max-width: 560px) {
          width: 100%;
        }
      }

      h4 {
        font-size: 24px;
        line-height: 30px;
        text-transform: uppercase;
        color: $white;
        font-weight: 700;

        @media (max-width: 820px) {
          font-size: 20px;
          line-height: 26px;
        }
      }
    }
  }
}

.inq {
  &-member {
    margin-top: 32px;
    margin-bottom: 10px;

    @media (max-width: 560px) {
      margin-top: 20px;
    }
  }

  &-name {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 5px;
    color: $white;

    @media (max-width: 560px) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  &-position {
    font-size: 16px;
    line-height: 20px;
    color: $white;
    font-weight: 300;

    @media (max-width: 560px) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &-info {
    display: flex;
    gap: 30px;
    margin-top: 20px;

    p,
    a {
      font-size: 20px;
      line-height: 25px;
      color: $white;

      @media (max-width: 560px) {
        font-size: 14px;
        line-height: 20px;
      }
    }

    &-wrap {
      padding-top: 10px;
    }
  }
}

.invstr {
  &-name {
    font-size: 16px;
    line-height: 20px;
    color: $dark;
    font-weight: 500;

    @media (max-width: 820px) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &-position {
    font-size: 14px;
    line-height: 18px;
    color: rgba($color: $dark, $alpha: 0.5);

    @media (max-width: 820px) {
      font-size: 12px;
      line-height: 16px;
    }
  }
}

.invst-wrap {
  margin-bottom: 20px;
}
.padding-0 {
  padding-top: 0px;
  hr {
    color: #77abd2;
  }
}
.advantages-wrap {
  p.text {
    font-family: "Outfit";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    text-align: justify;

    /* Black */

    color: #38495d;

    opacity: 0.7;
  }
  h3.head {
    background: #0a3e68;
    color: #fff;
    margin-top: 100px;
    padding: 20px;
  }
  .advantages-box {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .growth-title {
    font-family: "Orpheus Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 32.8801px;
    line-height: 53px;
    /* or 163% */

    display: flex;
    align-items: center;
    letter-spacing: 1.64401px;
    text-transform: uppercase;

    color: #161616;
  }
  .growth-desc {
    font-family: "Outfit";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    /* or 160% */

    /* Black */

    color: #161616;

    opacity: 0.7;
  }
  .growing-wrap {
    margin-top: 100px;
    margin-bottom: 100px;
    img {
      width: 100%;
    }
    .box {
      background: #deebf4;
      text-align: center;
      color: #0a3e68;
    }
  }
}
.com-title {
  color: #000;
  font-size: 48px;
  font-weight: 600;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 28px;
  }

  @media (max-width: 560px) {
    font-size: 28px;
  }
}
.advantages-sec {
  background: #fff;
  margin-block: 110px;
  @include padding-x-140;

  @media (max-width: 1024px) {
    margin-block: 65px;
  }

  .box_content {
    background: #fff;
    height: unset;
    h4 {
      font: normal normal 800 32px/44px Muli;
    }
  }
}
.menu-wrap {
  a {
    font-family: "prometo", sans-serif;
  }
}

.leftborder_ {
  padding: 5px 5px 5px 15px;
  border-left: 2px solid #2a7a13;
  margin-top: 15px;
  // height: 80px;
}
