.playbook-wrap {
    @include padding-x;
}

.svg-wrap {
    margin-top: 60px;
}

.svg-link {
    width: 265px;

    svg {
        @media (max-width: 560px) {
            height: 65px;
        }
    }
}

.esg-icon {
    height: 95px;
    margin-bottom: 30px;
    margin-top: 50px;

    @media (max-width: 1200px) {
        margin-top: 40px;
        margin-bottom: 25px;
    }

    @media (max-width: 820px) {
        margin-top: 30px;
    }

    @media (max-width: 560px) {
        height: 75px;
        overflow: hidden;
        margin-top: 0;

        img {
            width: 100%;
        }
    }

    &-name {
        font-weight: 700;
        font-size: 15px;
        line-height: 19px;
        text-transform: uppercase;
        text-align: center;

        @media (max-width: 560px) {
            font-size: 13px;
            line-height: 17px;
        }
    }
}

.focus-area {
    @include padding-x;
}