@import './variables';

@import "~bootstrap/scss/bootstrap";
@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";
@import '~aos/dist/aos.css';

@import './mixin';
@import './global';
@import './header';
@import './footer';
@import './banner';
@import './about';
@import './brief';
@import './number';
@import './insights';
@import './team';
@import './plant';
@import './info';
@import './bottom-card';
@import './inner-banner';
@import './about-page';
@import './technology';
@import './team-page';
@import './contact';
@import './portfolioPatners';
@import './esg';
@import './news';
@import './investor';
@import './loader';
@import './leader';
@import './event';
@import './admin';
@import './banner-bottomcontact';
